<template>
    <section id="dashboard-practice">
        <b-row class="match-height">
            <b-col lg="6">
                <practice-list/>
            </b-col>
            <b-col lg="6">
                <task-list/>
            </b-col>
        </b-row>
    </section>
</template>
<script>
    import {BRow,BCol} from 'bootstrap-vue'
    import PracticeList from './PracticeList.vue'
    import TaskList from './TaskList.vue'
    export default {
        components: {
            PracticeList,
            TaskList,
            BRow,
            BCol
        }
    }
</script>