<template>
    <div>
        <div class="filter-wrap">
            <v-select v-model="catFilter" placeholder="Select Category" attach hide-details @input="getAllReviewTasks()" :options="filterOptions"></v-select>
        </div>
        <b-card v-if="totalTasks === 0" no-body class="mb-0">
            <div class="m-2 pb-4 text-center">There are no tasks yet.</div>
        </b-card>
        <b-card v-if="totalTasks > 0" no-body class="mb-0">
            <b-table :items="tasks" responsive :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found">
                <template #cell(name)="data">
                    <b-link v-if="data.item.task_type === 'practice'" :to="{name:'iab-other-practice',params:{id: data.item.task_type_id}}">{{ data.item.name }}</b-link>
                    <b-link v-else-if="data.item.task_type === 'owner'" :to="{name:'iab-client-owner',params:{id: data.item.task_type_id}}">{{ data.item.name }}</b-link>
                    <b-link v-else-if="data.item.task_type === 'client'" :to="{name:'iab-client',params:{id: data.item.task_type_id}}">{{ data.item.name }}</b-link>
                    <b-link v-else-if="data.item.task_type === 'contractor'" :to="{name:'iab-contractor',params:{id: data.item.task_type_id}}">{{ data.item.name }}</b-link>
                    <b-link v-else-if="data.item.task_type === 'staff'" :to="{name:'iab-staff',params:{id: data.item.task_type_id}}">{{ data.item.name }}</b-link>
                    <span v-else>{{ data.item.name }}</span>
                </template>
                <template #cell(type)="data">
                    <b-badge v-if="data.item.type === 'system'" variant="success">System</b-badge>
                    <b-badge v-if="data.item.type === 'manual'" variant="warning">Manual</b-badge>
                </template>
                <template #cell(priority)="data">
                    <b-badge v-if="data.item.priority === 'low'" variant="success">Low</b-badge>
                    <b-badge v-if="data.item.priority === 'medium'" variant="warning">Medium</b-badge>
                    <b-badge v-if="data.item.priority === 'high'" variant="danger">High</b-badge>
                </template>
                <template #cell(category)="data">
                    <span>{{ data.item.category }}</span>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalTasks" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text><feather-icon icon="ChevronLeftIcon" size="18"/></template>
                            <template #next-text><feather-icon icon="ChevronRightIcon" size="18"/></template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>
<script>
    import {BCard,BRow,BCol,BTable,BLink,BBadge,BPagination} from 'bootstrap-vue'
    import taskService from '@core/services/task/useTask'
    import vSelect from 'vue-select'
    import {$themeConfig} from "@themeConfig";
    import authService from "@core/services/auth/useAuth";
    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BTable,
            BLink,
            BBadge,
            BPagination,
            vSelect
        },
        data(){
            return {
                catFilter:'',
                userData: {},
                tasks: [],
                totalTasks: 0,
                filterOptions: ["Beneficial Owner","File Review"],
                tableColumns: [
                    {key: 'name',label:"Task",sortable: true},
                    {key: 'type',label:"Type",sortable: true},
                    {key: 'assignee.name',label:"Assignee",sortable: true},
                    {key: 'priority',label:"Priority",sortable: true},
                    {key: 'category',label:"Category",sortable: true},
                    {key: 'due_date_format',label:"Due Date",sortable: true}
                ],
                perPage: 20,
                currentPage: 1,
                searchQuery: ""
            }
        },
        methods: {
            getAllReviewTasks(){
                taskService.getAllReviewTasks({category: this.catFilter}).then(response => {
                  let authorized = authService.isAuthorized(response.data);

                  if ( authorized ){
                    this.tasks = response.data;
                    this.totalTasks = this.tasks.length;
                  }else{
                    window.location = $themeConfig.app.notFoundRedirect;
                  }
                }).catch(error => {
                    if(error.response.status == 403){
                      window.location = $themeConfig.app.suspendedAccountRedirect;
                    }
                });
            }
        },
        mounted(){
            this.getAllReviewTasks();
        }
    }
</script>
<style lang="scss" scoped>
    .per-page-selector{width:90px;}
    .filter-wrap{display:flex;width:220px;margin-left:auto;margin-bottom:20px;}
    .filter-wrap .v-select{flex:1;}
</style>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>