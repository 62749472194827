<template>

  <div>
    <b-card
        v-if="totalPractices === 0"
        no-body
        class="mb-0"
    >

      <div class="m-2 pb-4 text-center">
        There are no practices yet.
      </div>
    </b-card>

    <!-- Table Container Card -->
    <b-card
        v-if="totalPractices > 0"
        no-body
        class="mb-0"
    >

      <b-table
          :items="practices"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
      >

        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-link :to="{ name: 'iab-other-practice', params: { id: data.item.id } }">{{ data.item.name }}</b-link>
        </template>

        <!-- Column: Threshold Rating -->
        <template #cell(thresholdRating)="data">
          <b-badge v-if="data.item.thresholdRating === 'low'" variant="success">Low Risk</b-badge>
          <b-badge v-if="data.item.thresholdRating === 'medium'" variant="warning">Medium Risk</b-badge>
          <b-badge v-if="data.item.thresholdRating === 'high'" variant="danger">High Risk</b-badge>
          <b-badge v-if="data.item.thresholdRating === 'very_high'" class="very-high-risk">Very High Risk</b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalPractices"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {avatarText, title} from '@core/utils/filter'
import practiceService from "@core/services/practice/usePractice";
import authService from "@core/services/auth/useAuth";
import {computed, ref} from "@vue/composition-api/dist/vue-composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {$themeConfig} from "@themeConfig";

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

    this.getPractices();
  },
  data() {
    return {
      userData: {},
      practices: [],
      totalPractices: 0,
      tableColumns: [
        { key: 'name', sortable: true },
        { key: 'structure', sortable: true },
        { key: 'thresholdRating', sortable: true },
        { key: 'totalRisk', sortable: true },
        {key: 'usertasks',label:"User Tasks",sortable: true}
      ],
      perPage: 20,
      currentPage: 1,
      searchQuery: ""
    }
  },
  methods: {
    getPractices() {
      practiceService.getAllPractices().then(response => {
        let authorized = authService.isAuthorized(response.data);

        if ( authorized ){
          this.practices = response.data;
          this.totalPractices = this.practices.length;
        }else{
          window.location = $themeConfig.app.notFoundRedirect;
        }
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
