import BaseService from "@core/services/baseService";

export default class AuthService extends BaseService{
    isAuthorized(data){
        if ( data.authorized === true ){
            return true;
        }
        if ( data.authorized === false ){
            return false;
        }

        return true;
    }
}